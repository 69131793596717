
import {defineComponent, reactive, toRefs} from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
    name: 'Error',
    setup ()
    {
        const route = useRoute();
        const now = new Date();
        const data = reactive({
            message: route.params.message,
            datetime: now.toString(),
            tpId: ''
        });

        // Handle special errors based on code
        if (route.params.code === '620')
        {
            // eslint-disable-next-line max-len
            data.message = 'The application you are trying to access requires a CCMS Account in good standing be linked to your Teleperformance ID. We were unable to find a CCMS Account linked to your Teleperformance ID. Please contact support to address the issue. You will not be able to access the application until this link is made.';
            data.tpId = route.params.message as string;
        }
        else if (route.params.code === '641')
        {
            // eslint-disable-next-line max-len
            data.message = 'The application you are trying to access requires a CCMS Account in good standing be linked to your Teleperformance ID. The Employee ID set on your Teleperformance ID does not belong to a valid employee record in CCMS. Please contact support to address the issue. You will not be able to access the application until this link is made.';
            data.tpId = route.params.message as string;
        }
        else if (route.params.code === '642')
        {
            // eslint-disable-next-line max-len
            data.message = 'The application you are trying to access requires a CCMS Account in good standing be linked to your Teleperformance ID. While there is a CCMS Account associated with your Teleperformance ID, that CCMS Account has been terminated.';
            data.tpId = route.params.message as string;
        }
        else if (route.params.code === '643')
        {
            // eslint-disable-next-line max-len
            data.message = 'The application you are trying to access requires a CCMS Account in good standing be linked to your Teleperformance ID. While there is a CCMS Account associated with your Teleperformance ID, that CCMS Account has been Locked.';
            data.tpId = route.params.message as string;
        }

        return {...toRefs(data) };
    }
});
